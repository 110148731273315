<script lang="ts" setup>
import type { NavMenu } from '~~/types/storyblok/navigation'

const props = defineProps<{ blok: NavMenu }>()

const items = computed(() => {
  return props.blok?.items || []
})
</script>

<template lang="pug">
nav(v-editable="blok")
  StoryblokComponent(
    v-for="item in items"
    :key="item._uid"
    :blok="item"
  )
</template>
